<template>
  <v-container>
    <v-card>
      <v-card-title class="text-uppercase">
        {{ $tc("models.notification", 2) }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <notifications load-more />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Notifications from "../components/notifications/Notifications.vue";

export default {
  components: {
    Notifications,
  },
};
</script>
